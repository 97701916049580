import { ArrowBack, ArrowForward, Delete } from "@mui/icons-material";
import { Box, Dialog, Grid } from "@mui/material";
import { PimoClassComponent, PimoReactComponent } from "@pimo/pimo-app-builder";
import { FC, useState } from "react";
import {
  DefaultValues,
  FieldError,
  FieldErrors,
  FieldValues,
  FormProvider,
  get,
  Path,
  PathValue,
  useForm,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
} from "react-hook-form";

import {
  CHECKBOX_FORM_FIELD_TYPE,
  CheckBoxFormField,
} from "./fields/checkbox-form-field";
import { DATE_FORM_FIELD_TYPE, DateFormField } from "./fields/date-form-field";
import {
  DROPDOWN_FORM_FIELD_TYPE,
  DropdownFormField,
} from "./fields/dropdown-form-field";
import {
  MULTI_TEXT_FORM_FIELD_TYPE,
  MultiTextFormField,
} from "./fields/multi-text-form-field/multi-text-form-field";
import {
  NUMBER_FORM_FIELD_TYPE,
  NumberFormField,
} from "./fields/number-form-field";
import { TEXT_FORM_FIELD_TYPE, TextFormField } from "./fields/text-form-field";
import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  CustomFieldComponent,
  FormFieldDefinition,
} from "./form-field";
import { FormFieldWrapper } from "./form-field-wrapper";
import { FormTab, FormTabDefinition } from "./form-tab";
import type {
  BaseLayoutElementDefinition,
  CustomLayoutElementComponent,
  LayoutElementDefinition,
} from "./layout-element";
import {
  FORM_LABEL_LAYOUT_ELEMENT_TYPE,
  FormLabelLayoutElement,
} from "./layout-elements/form-label";
import {
  FORM_TAB_TITLE_LAYOUT_ELEMENT_TYPE,
  FormTabTitleLayoutElement,
} from "./layout-elements/form-tab-title";
import {
  SUBHEADLINE_LAYOUT_ELEMENT_TYPE,
  SubheadlineLayoutElement,
} from "./layout-elements/subheadline";
import { LayoutFieldWrapper } from "./layout-field-wrapper";
import {
  FormNavigationAction,
  FormNavigationActionConfig,
  FormNavigationWrapper,
  FormNavigationWrapperProps,
} from "./navigation/navigation-wrapper";

export type DeriveFCProps<Component> = Component extends FC<infer Props>
  ? Props
  : never;

const DEFAULT_FORM_FIELDS = {
  [TEXT_FORM_FIELD_TYPE]: TextFormField,
  [MULTI_TEXT_FORM_FIELD_TYPE]: MultiTextFormField,
  [NUMBER_FORM_FIELD_TYPE]: NumberFormField,
  [CHECKBOX_FORM_FIELD_TYPE]: CheckBoxFormField,
  [DROPDOWN_FORM_FIELD_TYPE]: DropdownFormField,
  [DATE_FORM_FIELD_TYPE]: DateFormField,
} as const satisfies {
  [K in FormFieldDefinition["type"]]: FC<
    Extract<FormFieldDefinition & AdditionalFormFieldProps, { type: K }>
  >;
};

const DEFAULT_LAYOUT_ELEMENTS = {
  [SUBHEADLINE_LAYOUT_ELEMENT_TYPE]: SubheadlineLayoutElement,
  [FORM_LABEL_LAYOUT_ELEMENT_TYPE]: FormLabelLayoutElement,
  [FORM_TAB_TITLE_LAYOUT_ELEMENT_TYPE]: FormTabTitleLayoutElement,
} as const satisfies {
  [K in LayoutElementDefinition["type"]]: FC<
    Extract<LayoutElementDefinition, { type: K }>
  >;
};

export type DefaultFormFields = typeof DEFAULT_FORM_FIELDS;
export type DefaultLayoutElements = typeof DEFAULT_LAYOUT_ELEMENTS;
export type CustomFormFields<
  Type extends string = never,
  Props extends BaseFormFieldDefinition<Type> & AdditionalFormFieldProps = never
> = Record<Type, CustomFieldComponent<Props>>;
export type CustomLayoutElements<
  Type extends string = never,
  Props extends BaseLayoutElementDefinition<Type> = never
> = Record<Type, CustomLayoutElementComponent<Props>>;

export type PimoFormEvent =
  | "delete"
  | "cancel"
  | "cancel-with-changes"
  | "close"
  | "submit"
  | "submit-without-changes"
  | "navigate-with-changes"
  | "navigate"
  | "confirm-up-to-date"
  | "show-alert-dialog";

export type ValidateFormData = (
  formData: Record<string, unknown>
) => Record<Path<FieldValues>, FieldError>;

export type ValidationTarget = "all-tabs" | "current-tab" | "none";

export type AdditionalFormProps = {
  actions?: {
    [key in FormNavigationAction]?: Partial<FormNavigationActionConfig>;
  };
  saveOnAction?: boolean;
  validationTarget?: ValidationTarget;
  validateFormData?: ValidateFormData;
  runValidationOn?: "change" | "blur";
  useWatchForEntireForm?: boolean;
  isCheckBoxChecked?: boolean;
  addCheckBoxToForm?: boolean;
  alertDialog?: React.FC;
  saveAndCloseLabel?: string;
  title?: string;
  /** if set to `true`, the tabs navigation is not displayed and the form is not artifically pulled to 100% screen width */
  inline?: boolean;
} & Pick<FormNavigationWrapperProps, "navigationSlot">;

export type FormNavigationDirection = "back" | "next" | "none";

export class PimoForm<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormValues extends Record<string, any>,
  CustomFormFieldDefinitions extends CustomFormFields = never,
  CustomLayoutElementDefinitions extends CustomLayoutElements = never
> implements
    PimoClassComponent<
      FormValues & AdditionalFormProps,
      PimoFormEvent,
      FormValues
    >
{
  private setFormTabsState?: React.Dispatch<
    React.SetStateAction<
      FormTab<
        FormValues,
        CustomFormFieldDefinitions,
        CustomLayoutElementDefinitions
      >[]
    >
  >;

  initializeFormTabsState(
    setState: React.Dispatch<
      React.SetStateAction<
        FormTab<
          FormValues,
          CustomFormFieldDefinitions,
          CustomLayoutElementDefinitions
        >[]
      >
    >
  ) {
    this.setFormTabsState = setState;
  }
  #availableFormFields = {
    ...DEFAULT_FORM_FIELDS,
  } as DefaultFormFields & CustomFormFieldDefinitions;

  #availableLayoutElements = {
    ...DEFAULT_LAYOUT_ELEMENTS,
  } as DefaultLayoutElements & CustomLayoutElementDefinitions;

  #flatFormTabsInOrderOfNavigation: FormTab<
    FormValues,
    CustomFormFieldDefinitions,
    CustomLayoutElementDefinitions
  >[] = [];

  #lastActionAttempted: "save-and-close" | "save-and-next" | "none" | null =
    null;

  #setFormValues?: UseFormSetValue<FormValues>;

  setFormValue(
    key: Path<FormValues>,
    value: PathValue<FormValues, Path<FormValues>>
  ) {
    this.#setFormValues?.(key, value, { shouldDirty: true });
  }

  getLastActionAttempted() {
    return this.#lastActionAttempted;
  }

  #formMethods?: UseFormReturn<FormValues> & {
    areAllTabsValid: () => Promise<boolean>;
  };

  #formTabs: FormTab<
    FormValues,
    CustomFormFieldDefinitions,
    CustomLayoutElementDefinitions
  >[] = [];

  #title?: string;

  #navigateCallback?: (direction: FormNavigationDirection) => void;

  public navigate(direction: FormNavigationDirection) {
    this.#navigateCallback?.(direction);
  }

  addFormTab(
    definition: FormTabDefinition
  ): FormTab<
    FormValues,
    CustomFormFieldDefinitions,
    CustomLayoutElementDefinitions
  > {
    const tab = new FormTab<
      FormValues,
      CustomFormFieldDefinitions,
      CustomLayoutElementDefinitions
    >(definition, () => this.#buildFlatFormTabs());

    this.#formTabs.push(tab);
    this.setFormTabsState?.([...this.#formTabs]);
    this.#buildFlatFormTabs();
    return tab;
  }

  setTitle(title: string) {
    this.#title = title;
  }

  /**
   * Builds a flat list of form tabs in the order they should be navigated through.
   *
   * The reason we need this is that `_formTabs` contains a tree structure of tabs and child tabs:
   *
   * - tab a
   *   - tab a.1
   *   - tab a.2
   *
   * - tab b
   *
   * However, for navigating through tabs, we need a flat list (tab a => tab a.1 => tab a.2 => tab b.)
   */
  #buildFlatFormTabs() {
    const flatTabs: FormTab<
      FormValues,
      CustomFormFieldDefinitions,
      CustomLayoutElementDefinitions
    >[] = [];

    function addFlattenedTabs(
      tab: FormTab<
        FormValues,
        CustomFormFieldDefinitions,
        CustomLayoutElementDefinitions
      >
    ) {
      flatTabs.push(tab);

      for (const childTab of tab.childTabs) {
        addFlattenedTabs(childTab);
      }
    }

    for (const tab of this.#formTabs) {
      addFlattenedTabs(tab);
    }

    this.#flatFormTabsInOrderOfNavigation = flatTabs;
  }

  getFormMethods = () => {
    return this.#formMethods;
  };

  async buildDynamicTabsAsynchronously(
    buildFunction: (form: this) => Promise<void>
  ): Promise<void> {
    this.#formTabs = [];
    await buildFunction(this);
  }

  render(): PimoReactComponent<
    FormValues & AdditionalFormProps,
    PimoFormEvent,
    FormValues
  > {
    return ({
      actions,
      fireEvent,
      inDialog,
      navigationSlot,
      saveOnAction,
      validateFormData,
      validationTarget = "current-tab",
      runValidationOn = "change",
      isCheckBoxChecked,
      addCheckBoxToForm = false,
      useWatchForEntireForm = false,
      alertDialog,
      title,
      inline,
      ...formData
    }) => {
      if (this.isEmptyOrAllUndefinedZero(formData)) {
        return <></>;
      }
      const [selectedTab, setSelectedTab] = useState(this.#formTabs[0]?.id);
      const [tabs, setTabs] = useState<
        FormTab<
          FormValues,
          CustomFormFieldDefinitions,
          CustomLayoutElementDefinitions
        >[]
      >(this.#formTabs);

      const AlertDialog = alertDialog;

      this.initializeFormTabsState(setTabs);

      const methods = useForm<FormValues>({
        defaultValues: (formData ?? undefined) as DefaultValues<FormValues>,
        mode: runValidationOn === "change" ? "all" : "onBlur",
      });

      this.#setFormValues = methods.setValue;

      const { handleSubmit, formState } = methods;
      const { dirtyFields, touchedFields } = formState;
      const hasChanges = Boolean(
        Object.keys(dirtyFields).length || Object.keys(touchedFields).length
      );

      const onCancel = () => {
        hasChanges ? fireEvent?.("cancel-with-changes") : fireEvent?.("cancel");
      };

      const onCheckBoxClick = () => {
        fireEvent?.("confirm-up-to-date");
      };

      const navigate = (direction: "next" | "back") => {
        const differenceForDirection = direction === "next" ? 1 : -1;

        const currentTabIndex = this.#flatFormTabsInOrderOfNavigation.findIndex(
          (tab) => tab.id === selectedTab
        );

        // find next tab with form elements, skip over tabs without elements
        let newTabIndex = currentTabIndex + differenceForDirection;
        let newTab = this.#flatFormTabsInOrderOfNavigation[newTabIndex];

        while (newTab?.formFields.length === 0) {
          newTabIndex += differenceForDirection;
          newTab = this.#flatFormTabsInOrderOfNavigation[newTabIndex];
        }

        if (newTab == null) {
          return;
        }

        setSelectedTab(newTab.id);
      };

      const navigateToTab = (id: string) => {
        let newTab = this.#flatFormTabsInOrderOfNavigation.find(
          (tab) => tab.id === id
        );

        if (newTab?.formFields.length === 0 && newTab.childTabs.length > 0) {
          newTab = newTab.childTabs[0];
        }

        if (!newTab) {
          return;
        }

        setSelectedTab(newTab.id);
      };

      const onSave = async (
        data: FormValues,
        overrideValidationTarget?: ValidationTarget
      ) => {
        switch (overrideValidationTarget ?? validationTarget) {
          case "all-tabs":
            if (!(await areAllTabsValid())) {
              return;
            }
            break;
          case "current-tab":
            if (!(await isCurrentTabValid())) {
              return alertDialog && fireEvent?.("show-alert-dialog");
            }
            break;
          default:
            break;
        }

        hasChanges
          ? fireEvent?.("submit", data)
          : fireEvent?.("submit-without-changes", data);
      };

      const onSaveAndNext = async (
        data: FormValues,
        overrideValidationTarget?: ValidationTarget
      ) => {
        switch (overrideValidationTarget ?? validationTarget) {
          case "all-tabs":
            if (!(await areAllTabsValid())) {
              return;
            }
            break;
          case "current-tab":
            if (!(await isCurrentTabValid())) {
              return alertDialog && fireEvent?.("show-alert-dialog");
            }
            break;
          default:
            break;
        }

        if (saveOnAction) {
          hasChanges
            ? fireEvent?.("submit", {
                ...data,
                navigationDirection: "next",
              })
            : fireEvent?.("submit-without-changes", {
                ...data,
                navigationDirection: "next",
              });
        }

        navigate("next");
      };

      const onDelete = () => {
        fireEvent?.("delete");
      };

      const onSaveAndBack = async (
        data: FormValues,
        overrideValidationTarget?: ValidationTarget
      ) => {
        switch (overrideValidationTarget ?? validationTarget) {
          case "all-tabs":
            if (!(await areAllTabsValid())) {
              return;
            }
            break;
          case "current-tab":
            if (!(await isCurrentTabValid())) {
              return alertDialog && fireEvent?.("show-alert-dialog");
            }
            break;
          default:
            break;
        }

        if (saveOnAction) {
          hasChanges
            ? fireEvent?.("submit", {
                ...data,
                navigationDirection: "back",
              })
            : fireEvent?.("submit-without-changes", {
                ...data,
                navigationDirection: "back",
              });
        }

        navigate("back");
      };

      const onSaveAndClose = async (
        data: FormValues,
        overrideValidationTarget?: ValidationTarget
      ) => {
        switch (overrideValidationTarget ?? validationTarget) {
          case "all-tabs":
            if (!(await areAllTabsValid())) {
              return;
            }
            break;
          case "current-tab":
            if (!(await isCurrentTabValid())) {
              return alertDialog && fireEvent?.("show-alert-dialog");
            }
            break;
          default:
            break;
        }

        hasChanges
          ? fireEvent?.("submit", {
              ...data,
            })
          : fireEvent?.("submit-without-changes", {
              ...data,
            });

        fireEvent?.("close");
      };

      const getFormErrors = async () => {
        const data = methods.getValues();
        const errors = validateFormData?.(data) as Record<
          Path<FieldValues>,
          FieldError
        >;

        for (const [key, value] of Object.entries(errors ?? {})) {
          methods.setError(key as Path<FormValues>, value);
        }

        await methods.trigger();

        return { ...(errors ?? {}), ...methods.formState.errors };
      };

      const isCurrentTabValid = async () => {
        const currentTab = this.#flatFormTabsInOrderOfNavigation.find(
          (tab) => tab.id === selectedTab
        );

        return isTabValid(currentTab, await getFormErrors());
      };

      const areAllTabsValid = async () => {
        let valid = true;
        const errors = await getFormErrors();

        this.#flatFormTabsInOrderOfNavigation.forEach((tab) => {
          if (!isTabValid(tab, errors)) {
            valid = false;
          }
        });

        return valid;
      };

      const isTabValid = (
        tab:
          | FormTab<
              FormValues,
              CustomFormFieldDefinitions,
              CustomLayoutElementDefinitions
            >
          | undefined,
        errors: FieldErrors<FieldValues>
      ) => {
        if (!tab) {
          return true;
        }

        let isValid = true;

        for (const field of tab?.formFields ?? []) {
          if (!this.#isFormFieldDefinition(field)) {
            continue;
          }

          const error = get(errors, field.name) as Record<
            Path<FieldValues>,
            FieldError
          >;

          if (error?.message) {
            methods.setError(field.name as Path<FormValues>, error);
            isValid = false;
          }
        }

        tab.hasErrors = !isValid;

        return isValid;
      };

      this.#formMethods = {
        areAllTabsValid,
        ...methods,
      };

      this.#navigateCallback = (direction: FormNavigationDirection) => {
        if (direction === "none") return;
        navigate(direction);
      };

      const activeTabIndex = this.#formTabs.findIndex(
        ({ id }) => id === selectedTab
      );
      const leftActions = [
        {
          disabled: activeTabIndex === 0,
          key: "save-and-back",
          label: "Back",
          onClick: () => onSaveAndBack(methods.getValues()),
          startIcon: <ArrowBack />,
          variant: "text",
          visible: true,
          ...(actions?.["save-and-back"] ?? {}),
        },

        {
          key: "delete",
          label: "Delete",
          visible: false,
          disabled: false,
          variant: "contained",
          color: "error",
          startIcon: <Delete />,
          onClick: onDelete,
          ...(actions?.["delete"] ?? {}),
        },
      ] as const satisfies FormNavigationActionConfig[];

      const rightActions = [
        ...(addCheckBoxToForm
          ? [
              {
                key: "confirm-up-to-date",
                isCheckBoxChecked,
                type: "checkbox",
                label:
                  "By clicking SAVE & CLOSE I confirm that the data is up to date",
                onClick: () => onCheckBoxClick(),
                visible: true,
              },
            ]
          : []),
        {
          key: "cancel",
          label: "Cancel",
          onClick: () => onCancel(),
          variant: "text",
          visible: true,
          ...(actions?.["cancel"] ?? {}),
        },
        {
          key: "save",
          label: "Save",
          onClick: () =>
            onSave(
              methods.getValues(),
              actions?.["save"]?.overrideValidationTarget
            ),
          variant: "text",
          visible: false,
          ...(actions?.["save"] ?? {}),
        },
        {
          key: "save-and-close",
          label: "Save & Close",
          onClick: () => {
            onSaveAndClose(
              methods.getValues(),
              actions?.["save-and-close"]?.overrideValidationTarget
            );
            this.#lastActionAttempted = "save-and-close";
          },
          variant:
            activeTabIndex === this.#formTabs.length - 1 ? "contained" : "text",
          visible: true,
          ...(actions?.["save-and-close"] ?? {}),
        },
        {
          endIcon: <ArrowForward />,
          key: "save-and-next",
          label: "Save & Next",
          onClick: () => {
            onSaveAndNext(
              methods.getValues(),
              actions?.["save-and-next"]?.overrideValidationTarget
            );
            this.#lastActionAttempted = "save-and-next";
          },
          variant: "contained",
          visible: (activeTabIndex ?? 0) <= this.#formTabs.length - 2,
          ...(actions?.["save-and-next"] ?? {}),
        },
      ].filter(Boolean) as FormNavigationActionConfig[];

      const fieldValues: FormValues = useWatchForEntireForm
        ? methods.watch()
        : methods.getValues();

      return (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((data) => onSaveAndNext(data))}
            style={{ height: "100%" }}
          >
            <FormNavigationWrapper
              actions={[leftActions, rightActions]}
              activeTab={selectedTab}
              inDialog={!!inDialog}
              navigationSlot={navigationSlot}
              onTabSelected={(tab) => navigateToTab(tab)}
              tabs={tabs}
              title={title || this.#title}
              inline={inline}
            >
              {tabs
                .flatMap((tab) => [tab, ...tab.childTabs])
                .map((tab, i) => {
                  return (
                    <Box key={i} hidden={selectedTab !== tab.id}>
                      <Grid container spacing={4}>
                        {tab.formFields.map((field, fieldIndex) => {
                          if (this.#isFormFieldDefinition(field)) {
                            if (
                              field.isVisibile &&
                              !field.isVisibile(fieldValues)
                            ) {
                              return;
                            }

                            const ElementForField =
                              this.#getElementForFormFieldByType(
                                field.type as
                                  | keyof DefaultFormFields
                                  | keyof CustomFormFieldDefinitions
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ) as FC<any>;

                            if (!ElementForField) {
                              return;
                            }

                            const errorMessage = get(
                              formState.errors,
                              field.name
                            )?.message as string | undefined;

                            return (
                              <FormFieldWrapper
                                description={field.description}
                                infoSlot={field.infoSlot}
                                key={`field-${fieldIndex}-field.${field.name}}`}
                                label={
                                  field.type !== "checkbox"
                                    ? field.label
                                    : undefined
                                }
                                uiOptions={field.uiOptions}
                              >
                                <ElementForField
                                  {...field}
                                  register={
                                    methods.register as UseFormRegister<FieldValues>
                                  }
                                  errorMessage={errorMessage}
                                  value={
                                    fieldValues?.[
                                      field.name as unknown as keyof typeof fieldValues
                                    ]
                                  }
                                  formData={{ ...formData, ...fieldValues }}
                                  getElementForFormFieldByType={(
                                    type:
                                      | keyof DefaultFormFields
                                      | keyof CustomFormFieldDefinitions
                                  ) => this.#getElementForFormFieldByType(type)}
                                />
                              </FormFieldWrapper>
                            );
                          } else if (this.#isLayoutElementDefinition(field)) {
                            if (
                              field.isVisibile &&
                              !field.isVisibile(fieldValues)
                            ) {
                              return;
                            }
                            const ElementForField =
                              this.#getElementForLayoutElementByType(
                                field.type
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ) as unknown as FC<any>;

                            if (!ElementForField) {
                              return;
                            }

                            return (
                              <LayoutFieldWrapper
                                {...field}
                                key={`${i}_${fieldIndex}`}
                              >
                                <ElementForField {...field} />
                              </LayoutFieldWrapper>
                            );
                          }
                        })}
                      </Grid>
                    </Box>
                  );
                })}
            </FormNavigationWrapper>
            <>{AlertDialog && <AlertDialog />}</>
          </form>
        </FormProvider>
      );
    };
  }

  #getElementForFormFieldByType(
    type: keyof DefaultFormFields | keyof CustomFormFieldDefinitions
  ) {
    return this.#availableFormFields[type];
  }

  #getElementForLayoutElementByType(
    type: keyof DefaultLayoutElements | keyof CustomLayoutElementDefinitions
  ) {
    return this.#availableLayoutElements[type];
  }

  #isFormFieldDefinition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any
  ): element is BaseFormFieldDefinition<string> {
    return !!(element as BaseFormFieldDefinition<string>).name;
  }

  #isLayoutElementDefinition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any
  ): element is BaseLayoutElementDefinition {
    return !!(element as BaseLayoutElementDefinition).type;
  }

  registerFormField<
    NameType extends keyof DefaultFormFields | keyof CustomFormFieldDefinitions
  >(
    type: NameType,
    component: NameType extends keyof DefaultFormFields
      ? DefaultFormFields[NameType]
      : NameType extends keyof CustomFormFieldDefinitions
      ? CustomFormFieldDefinitions[NameType]
      : never
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.#availableFormFields[type] = component;
  }

  registerLayoutElement<
    NameType extends
      | keyof DefaultLayoutElements
      | keyof CustomLayoutElementDefinitions
  >(
    type: NameType,
    component: NameType extends keyof DefaultLayoutElements
      ? DefaultLayoutElements[NameType]
      : NameType extends keyof CustomLayoutElementDefinitions
      ? CustomLayoutElementDefinitions[NameType]
      : never
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.#availableLayoutElements[type] = component;
  }
  isEmptyOrAllUndefinedZero(obj: Record<string, unknown>) {
    // If object has no properties, consider it empty.
    if (Object.keys(obj).length === 0) {
      return true;
    }

    // Otherwise, check each value.
    for (const value of Object.values(obj)) {
      // If any value is not `undefined` or `0`, return false.
      if (value != null) {
        return false;
      }
    }

    // If we reach here, all values are `undefined` or `0`.
    return true;
  }
}

/**
 * Wraps the regular Pimo form into a dialog.
 */
export class PimoFormDialog<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormValues extends Record<string, any>,
  CustomFormFieldDefinitions extends CustomFormFields = never,
  CustomLayoutElementDefinitions extends CustomLayoutElements = never
> extends PimoForm<
  FormValues,
  CustomFormFieldDefinitions,
  CustomLayoutElementDefinitions
> {
  render(): PimoReactComponent<
    FormValues & {
      dialogStyles?: Record<string, unknown>;
    } & AdditionalFormProps,
    PimoFormEvent,
    FormValues & {
      path?: string;
    }
  > {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const SuperPimoForm: React.FC<any> = super.render();

    return ({ fireEvent, ...formData }) => {
      return (
        <Dialog
          open
          fullScreen
          sx={{ ...formData.dialogStyles, m: "5%" }}
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <SuperPimoForm fireEvent={fireEvent} {...formData} inDialog />
        </Dialog>
      );
    };
  }
}
