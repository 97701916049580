import { Box, Card, Chip, styled, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export interface HighlightCardProps {
  /** The header text */
  header: string;
  /** The main content or value (optional) */
  content?: string | number;
  /** Array of chips */
  chips?: {
    /** Subtitle for the chip (optional) */
    subtitle?: string;
    /** Chip label */
    label: string;
    /** Background color for the chip */
    backgroundColor?: string;
    /** Text color for the chip */
    textColor?: string;
  }[];
}

const StyledHighlightCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "15px",
  boxShadow: theme.shadows[1],
  minWidth: "150px",
  height: "170px",
  gap: theme.spacing(1.5),
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.0rem",
  fontWeight: 500,
  lineHeight: 1.45,
  marginBottom: theme.spacing(1.5),
  color: theme.palette.text.primary,
  textAlign: "left",
  width: "100%",
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  fontWeight: 500,
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
}));

const ChipGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  marginTop: theme.spacing(2),
}));

const CenteredChipWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
}));

export const HighlightCard: PimoReactComponent<HighlightCardProps> = ({
  header,
  content,
  chips = [],
}) => {
  const isSingleChip = chips.length === 1;

  return (
    <StyledHighlightCard>
      <HeaderTypography variant="subtitle1">{header}</HeaderTypography>
      {content && <ContentTypography variant="h4">{content}</ContentTypography>}
      {isSingleChip ? (
        <CenteredChipWrapper>
          <Chip
            label={chips[0].label}
            sx={{
              background: chips[0].backgroundColor || "green",
              height: 28,
              minWidth: 70,
              maxWidth: "fit-content",
              color: chips[0].textColor || "white",
            }}
          />
        </CenteredChipWrapper>
      ) : (
        <ChipGroup>
          {chips.map((chip, index) => (
            <Box key={index} textAlign="center">
              {chip.subtitle && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "0.75rem",
                    color: "gray",
                    marginBottom: 0.5,
                  }}
                >
                  {chip.subtitle}
                </Typography>
              )}
              <Chip
                label={chip.label}
                sx={{
                  background: chip.backgroundColor || "blue",
                  height: 28,
                  maxWidth: "fit-content",
                  padding: "0 8px",
                  color: chip.textColor || "white",
                }}
              />
            </Box>
          ))}
        </ChipGroup>
      )}
    </StyledHighlightCard>
  );
};

export default HighlightCard;
